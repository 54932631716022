import React, { Fragment } from 'react'
import { get, isBoolean, isEmpty, map, round, size } from 'lodash'

import { Block } from 'components/common'
import GroupHeader from 'components/form/group-header'
import AuditItemDeprecated from '../audit-item-deprecated'
import AuditItem from '../audit-item'
import { useFlags } from 'components/flags/hook'

export default function QuestionWrapper(props) {
  const { isEditing, items, formValues, groupScores, readOnly } = props

  const flags = useFlags()

  const groups = items.reduce((accum, item, index) => {
    const groupId = item.group.id

    accum[groupId] = accum[groupId] || {
      canSkip: !!item.group.canSkip,
      items: [],
      label: item.group.label,
      skipped: !!item.group.skipped,
      firstItemIndex: index,
      groupScore:
        groupScores && groupScores[groupId]
          ? { ...groupScores[groupId] }
          : { actual: 0, max: 0, result: 0 },
    }
    accum[groupId].items.push(item)
    return accum
  }, {})

  return map(groups, (group, groupId) => {
    const { canSkip, items: groupItems, groupScore, label, skipped } = group

    const fieldGroupNumber = Number(groupId) + 1
    const fieldGroupsLength = size(groups)

    const showSkipCheckbox = isBoolean(canSkip)
      ? canSkip
      : fieldGroupsLength > 1

    const formPath = `groups[${groupId}]`

    const groupFormValues = get(formValues, `groups.${groupId}`, {})
    const formGroupSkipped = get(groupFormValues, 'skipped', false)

    const hasScore = !isEmpty(groupScore)

    const scoreDisplay = hasScore
      ? `${round(groupScore.actual, 2)}/${round(groupScore.max, 2)} (${
          groupScore.percentageResult ? groupScore.percentageResult : 0
        }%)`
      : ''

    return (
      <Fragment key={groupId}>
        <GroupHeader
          fieldGroupNumber={fieldGroupNumber}
          fieldGroupsLength={fieldGroupsLength}
          groupScoreString={scoreDisplay}
          hasScore={hasScore}
          currSkipped={formGroupSkipped}
          isEditing={isEditing}
          group={group}
          label={label}
          formPath={formPath}
          readOnly={readOnly}
          canSkip={showSkipCheckbox}
          prevSkipped={skipped}
        >
          <Block paddingTop={10}>
            {groupItems.map(groupItem => {
              const { label, scores, _id, required } = groupItem
              if (flags.rolloutAuditQuestionInfo) {
                return (
                  <AuditItem
                    key={_id}
                    fieldPath={_id}
                    readOnly={readOnly}
                    required={required}
                    title={label}
                    scores={scores}
                    formValues={formValues}
                  />
                )
              }
              return (
                <AuditItemDeprecated
                  key={_id}
                  fieldPath={_id}
                  readOnly={readOnly}
                  required={required}
                  title={label}
                  scores={scores}
                />
              )
            })}
          </Block>
        </GroupHeader>
      </Fragment>
    )
  })
}
