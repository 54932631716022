import { LocaleResource } from '.'

const es419: LocaleResource = {
  'activityPanel.filter.allActivity': 'Toda actividad',
  'activityPanel.filter.audits': 'Auditorías',
  'activityPanel.filter.exceptions': 'Excepciones',
  'activityPanel.filter.issues': 'Problemas',
  'activityPanel.filter.shifts': 'Cambios',
  'activityPanel.filter.tasks': 'Tareas',
  'activityPanel.rowContent.exception.closed': 'Cerrada',
  'activityPanel.rowContent.exception.closedDuration':
    'Cerrado: {{time}} ({{duration}})',
  'activityPanel.rowContent.exception.duration': 'Duración',
  'activityPanel.rowContent.exception.open': 'Excepción abierta',
  'activityPanel.rowContent.exception.opened': 'Abierta',
  'activityPanel.rowContent.shiftEnd': 'Cambio finalizado',

  'tooltip.title.confirmLocation': 'Ubicación en inicio de turno',
  'tooltip.message.confirmLocation':
    'Esta configuración requerirá que los usuarios móviles confirmen su ubicación al comenzar un turno. Para inicios de turnos automáticos, deben confirmar una ubicación para iniciar sesión. Para inicios de turnos manuales, deben seleccionar una ubicación para comenzar el cambio.',

  'alert.message.exportConfirmationConfirm':
    '¿Está seguro de que desea exportar los registros {{totalCount}} seleccionados?',
  'alert.message.exportConfirmationError':
    'Hubo un problema al exportar tus datos. Vuelva a intentarlo o, si el problema persiste, póngase en contacto con el servicio de asistencia.',
  'alert.message.exportConfirmationSuccess':
    'Exportación de datos creada con éxito. La exportación estará disponible en la pestaña <underline>Informes > Exportaciones</underline> en breve.',
  'alert.message.exportConfirmationWarning':
    'Tamaño de exportación demasiado grande. Filtre los resultados a menos de 500.000 registros.',
  'alert.message.exportConfirmationWarningPdf':
    'Tamaño de exportación demasiado grande. Filtre los resultados a menos de 1000 registros.',
  'alert.message.pageNotFound':
    'No se pudo encontrar la página que buscaba. Comuníquese con Soporte.',
  'alert.message.profileSuccessfullyUpdated':
    'Tú perfil ha sido actualizado satisfactoriamente',
  'alert.message.requiredDataMissing': 'Faltan datos obligatorios',
  'alert.message.selectBeforeAddingSignal':
    'Seleccione una ubicación, edificio o geovalla antes de agregar una señal',
  'alert.message.signalMustBeInSelectedArea':
    'La señal debe crearse en el área seleccionada',
  'alert.message.unableToCreateSignal':
    'No se pudo crear la señal. Comuníquese con el soporte.',
  'alert.message.unableToExportPDF':
    'No se puede exportar PDF. Vuelva a intentarlo o póngase en contacto con el servicio de asistencia.',
  'alert.networkError':
    'Hubo un problema con la solicitud debido a un error de red.',
  'alert.noItemsCouldBeFound': 'No se encontró ningún {{artículo}}',
  'alert.noSchedulesFound': 'No se encontró ningún programa',
  'alert.noZonesToShow': 'No hay zonas disponibles para mostrar',
  'alert.pleaseSelectALocation': 'Elija una ubicación',
  'alert.pleaseSelectFilter': 'Seleccione al menos un filtro de ubicación',
  'alert.title.addSignal': 'Agregar señal',
  'alert.title.error': 'Error',
  'alert.message.locationAddTaskTemplateLocked':
    'El modelo de esta tarea está bloqueado y no se puede agregar a esta ubicación. Para asignar esta ubicación a este formulario, modifíquelo desde la pantalla de plantillas.',
  'alert.message.locationAddIssueTemplateLocked':
    'La plantilla de esta tarea está bloqueada y no se puede agregar a esta ubicación. Para asignar esta ubicación a este formulario, edítelo desde la pantalla de Plantillas',
  'alert.message.locationAddAuditTemplateLocked':
    'La plantilla de esta auditoría está bloqueada y no se puede agregar a esta ubicación. Para asignar esta ubicación a este formulario, edítelo desde la pantalla de Plantillas.',
  'alert.message.reinviteUserSentTo': 'La invitación se reenvió con éxito a',
  'alert.message.reinviteUserSentToError':
    'La invitación no se pudo reenviar a',
  'alert.message.reinviteUserSent': 'La invitación se reenvió con éxito',
  'alert.message.reinviteUserSentError': 'La invitación no se pudo reenviar',

  'reinviteUser.statusBanner.message': `Esta cuenta fue invitada <1>{{difference}}</1>, verifique que la dirección de correo electrónico sea correcta a continuación.`,
  'reinviteUser.statusBanner.saveToResend':
    'Guardar cambios para reenviar la invitación.',

  'areas.jobNumberTooltip.message':
    'Para clientes de WinTeam, pueden hacer referencia al número de trabajo relacionado',
  'areas.jobNumberTooltip.title': 'Número de trabajo de WinTeam',

  'audit.targetTooltip.message':
    'Agregue el número que desee como estándar de rendimiento en el campo Objetivo. Ingresar un "85" en el campo Objetivo establecería el estándar de medida en 85%.',
  'audit.targetTooltip.title': 'Introduce el Objetivo',
  'audit.question.addInfoAsset': 'Foto',
  'audit.question.addInfoComment': 'Comentario',
  'audit.score.additionalInformation': 'Agregar información adicional',
  'placeholder.additionalInformationDropdown': 'Additional info req.',
  'auditPanel.header.audit': 'Auditoría',
  'auditPanel.header.newAudit': 'Nueva auditoría',

  backToSignIn: 'Regresar al inicio de sesión de la aplicación',

  'button.add': 'Agregar',
  'button.addAnother': 'Agrega otro',
  'button.addBuilding': 'Agregar edificio',
  'button.addField': 'Agregar campo',
  'button.addGeofence': 'Agregar geocercas',
  'button.addGroup': 'Agregar grupo',
  'button.addOption': 'Agregar opción',
  'button.addQuestion': 'Agregar pregunta',
  'button.addQuote': 'Agregar cotización',
  'button.addServiceHours': 'Agregar horas de servicio',
  'button.addUser': 'Agregar usuario',
  'button.addUsers': 'Agregar usuarios',
  'button.addingUsers': 'Agregar usuarios',
  'button.back': 'Atrás',
  'button.cancel': 'Cancelar',
  'button.clear': 'Despejar',
  'button.clearFilters': 'Despejar filtros',
  'button.completeSignup': 'Registro completo',
  'button.confirm': 'Confirmar',
  'button.continue': 'Continuar',
  'button.delete': 'Borrar',
  'button.deleteConfirm': '¿Está seguro(a) que desea borrar {{name}}?',
  'button.deleteConfirm_schedule':
    '¿Está seguro(a) que desea borrar este programa? Al borrar el programa, se eliminarán del mismo todas las instancias activas y futuras.',
  'button.deleteConfirm_small': '¿Confirma borrar? ',
  'button.deleteConfirm_unnamed': '¿Está seguro(a) que desea borrar? ',
  'button.deviceRel': 'Dispositivo Rel',
  'button.discard': 'Cancelar',
  'button.discardConfirm': '¿Está seguro(a) que desea cancelar sus cambios? ',
  'button.discardConfirm_small': '¿Cancelar cambios? ',
  'button.duplicate': 'Duplicado',
  'button.edit': 'Editar',
  'button.editBoundaries': 'Editar límites',
  'button.exit': 'Salir',
  'button.fetchingApplication': 'Buscando aplicación',
  'button.filter': 'Filtro',
  'button.forgotPassword': 'Olvidé mi contraseña',
  'button.goLive': 'Publicar en vivo',
  'button.hide': 'Esconder',
  'button.invite': 'Invitar',
  'button.inviteUser': 'Invitar usuario',
  'button.invitingUser': 'Invitando usuario',
  'button.legend': 'Leyenda',
  'button.live': 'En vivo',
  'button.map': 'MAPA',
  'button.moveDown': 'Mover hacia abajo',
  'button.moveUp': 'Mover hacia arriba',
  'button.newApplication': 'Nueva aplicación',
  'button.newEntry': 'Nueva entrada',
  'button.newGroup': 'Nuevo grupo',
  'button.newLoop': 'Nuevo bucle',
  'button.newRole': 'Nuevo papel',
  'button.newSchedule': 'Nuevo programa',
  'button.newSignal': 'Nueva señal',
  'button.newTemplate': 'Nueva plantilla',
  'button.next': 'Próximo',
  'button.now': 'Ahora',
  'button.pdf': 'PDF',
  'button.printCodes': 'Imprimir códigos',
  'button.printLabels': 'Imprimir etiquetas',
  'button.processing': 'Procesando',
  'button.qrCodes': 'Códigos QR',
  'button.remove': 'Retirar',
  'button.removeFormGroupConfirm': '¿Eliminar grupo de formularios?',
  'button.resendInvite': 'Reenviar invitación',
  'button.reset': 'Reiniciar',
  'button.save': 'Guardar',
  'button.saveConfirm': '¡Está seguro(a) que desea guardar sus cambios? ',
  'button.saveConfirm_schedule':
    '¿Está seguro(a) que desea actualizar este programa? Al actualizar el programa se reemplazarán todas las instancias activas y futuras del programa.',
  'button.saveConfirm_small': '¿Guardar cambios?',
  'button.saveGroup': 'Guardar el grupo',
  'button.saving': 'Guardando',
  'button.signIn': 'Iniciar sesión',
  'button.syncing': 'Sincronizando',
  'button.today': 'Hoy',
  'button.update': 'Actualizar',

  'error.applicationNotFound': 'No se pudo encontrar la aplicación',
  'error.duplicateDocument':
    'Los datos que has enviado duplican un documento que ya existe.',

  'inputSelect.selectAll': 'Seleccionar todas las {{totalCount}} {{type}}s',
  'inputSelect.showCount': 'Mostrando {{totalCount}}  {{type}}s',
  'inputSelect.removeSelected': 'Eliminar {{selectedCount}}  {{type}}s',

  'issuePanel.header.issue': 'Problema',
  'issuePanel.header.newIssue': 'Nuevo problema',

  label12HourClock: 'Reloj de 12 horas',
  label24HourClock: 'Reloj de 24 horas',
  labelAccessPermissions: 'Derechos de acceso',
  labelActivity: 'Actividad',
  labelActivityFeed: 'Fuente de actividades',
  labelAddress: 'Dirección',
  labelAllDay: 'Todo el dia',
  labelAllLocations: 'Todas las ubicaciones',
  labelAllLocationGroups: 'Todos los grupos de ubicación',
  labelAllTitle: 'Todo {{title}}',
  labelAlwaysBackground: 'Siempre (fondo)',
  labelAnswer: 'Respuesta',
  labelAppDebugging: 'Depuración de la aplicación',
  labelArea: 'Área',
  labelAreas: 'Áreas',
  labelAreasAndSignals: 'Áreas y Señales',
  labelAssignTo: 'Asignar a',
  labelAssignedTo: 'Asignado a',
  labelAssignee: 'Asignado',
  labelAssignees: 'Asignados',
  labelAttachments: 'Documentos adjuntos',
  labelAudit: 'Auditoría',
  labelAuditGroups: 'Auditar grupos',
  labelAuditScoresAverageByLocation:
    'Auditar calificaciones (promedio) por ubicación',
  labelAuditScoresAverageOverTime:
    'Auditar calificaciones (promedio) en el tiempo',
  labelAuditTemplates: 'Auditar plantillas',
  labelAudits: 'Auditorías',
  labelAuthentication: 'Autenticación',
  labelAutomatically: 'Automáticamente',
  labelAverageExceptionDuration: 'Duración media de las excepciones',
  labelBackInTime: 'Tiempo atrás',
  labelBeacon: 'Beacon',
  labelBeaconMajor: 'Beacon Importante',
  labelBeaconMinor: 'Beacon Menor',
  labelCheckboxes: 'Casillas de verificación',
  labelCity: 'Ciudad',
  labelCloseTime: 'Hora de cierre',
  labelClosed: 'Cerrado',
  labelComments: 'Comentarios',
  labelConfirmNewPassword: 'Confirmar nueva contraseña',
  labelConfirmLocation: 'Sí - Confirmar ubicación',
  labelConfirmGeofence: 'Sí - Confirmar geovalla',
  labelConfirmLocationAndGeofence: 'Sí - Confirmar ubicación y geovalla',
  labelEndShiftConfirmGeofence:
    'Debe estar dentro de la ubicación de inicio de turno, pero permitir el desvío',
  labelEndShiftCopyStartShiftLocation: 'Copiar ubicación de inicio de turno',
  labelEndShiftNone:
    'Sin restricciones: copie la ubicación de inicio del turno si no la conoce',
  labelContent: 'Contenido',
  labelContentEntries: 'Entradas de contenido',
  labelContentTemplate: 'Plantilla de contenido',
  labelContentTemplates: 'Plantillas de contenido',
  labelCountry: 'País',
  labelCreate: 'Crear',
  labelCreateNewGroup: 'Crear un nuevo grupo',
  labelCreated: 'Creado',
  labelDayforce: 'Dayforce',
  labelDayforceEmployeeBadgeNumber: 'Número de placa de empleado',
  labelDailyLocationReport: 'Informe diario de ubicación',
  labelDailyLocationReports: 'Informes diarios de ubicación',
  labelDailyShiftReport: 'Informes diarios de turno',
  labelDashboard: 'Tablero',
  labelDate: 'Fecha',
  labelDayforceLocationXRefCode: 'Código de ubicación de Dayforce',
  labelDayDate: 'Día / Fecha',
  labelDays: 'Dias',
  labelDefaultRole: 'Función predeterminada',
  labelDefaultRoleDescription:
    'Esta es la función predeterminada para nuevos usuarios',
  labelDelete: 'Eliminar',
  labelDescription: 'Descripción',
  labelDetails: 'Detalles',
  labelDisplayAlways: 'Mostrar siempre',
  labelDisplayImage: 'Mostrar imagen',
  labelDisplayOnFormEntryOnly:
    'Mostrar únicamente en la entrada del formulario',
  labelDisplayText: 'Mostrar texto',
  labelDuration: 'Duración',
  labelDuressAlerts: 'Alertas de coerción',
  labelEdit: 'Editar',
  labelEditGroup: 'Editar grupo',
  labelEditGroupDetails: 'Editar detalles del grupo',
  labelEmail: 'Correo electrónico',
  labelEmailOrUsername: 'Correo electrónico o nombre de usuario',
  labelEmployee: 'Empleado',
  labelEmployeeNumber: 'Número de empleado',
  labelEnabled: 'Habilitado',
  labelEnd: 'Finalizar',
  labelEndDate: 'Finalizar fecha',
  labelEndLocation: 'Ubicación final',
  labelEndSchedule: 'Finalizar programa',
  labelEndShift: 'Finalizar turno',
  labelEndTime: 'Hora de finalización',
  labelEndShiftRestriction: 'Restricciones de fin de turno',
  labelEnglishUs: 'Inglés (EE. UU.)',
  labelEnglishUsShort: 'Inglés (EE. UU.)',
  labelEnter: 'Ingresar',
  labelEvent: 'Evento',
  labelEventType: 'Tipo de evento',
  labelEvents: 'Eventos',
  labelExceptionClosed: 'Excepción cerrada',
  labelExceptionOpened: 'Excepción abierta',
  labelExceptions: 'Excepciones',
  labelExit: 'Salida',
  labelExport: 'Exportación',
  labelExports: 'Exportaciones',
  labelField: 'Campo',
  labelFile: 'Archivo',
  labelFirstName: 'Primer nombre',
  labelFloors: 'Piso(s)',
  labelFloorsTitle: 'Pisos',
  labelFooterFields: 'Campos de pie de página',
  labelFormTemplates: 'Plantillas de formularios',
  labelFrenchCa: 'Francés (canadiense)',
  labelFrenchCaShort: 'Francés (CA)',
  labelFrom: 'Desde',
  labelGPS: 'GPS',
  labelGroupLabel: 'Etiqueta del grupo',
  labelGroupName: 'Nombre del grupo',
  labelGroupScore: 'Puntaje de grupo',
  labelHeaderFields: 'Campos de encabezado',
  labelHighest: 'Más alto',
  labelHours: 'Horas',
  labelID: 'ID',
  labelInactiveRole: 'Rol inactivo',
  labelInactiveRoleDescription:
    'Este es el rol destinado a usuarios inactivos.',
  labelInProgress: 'En curso',
  labelIncludeUnassignedLocationData:
    'Incluir datos de ubicaciones no asignadas',
  labelInvited: 'Invitado',

  labelIssue: 'Expedir',
  labelIssueTemplates: 'Plantillas de problemas',
  labelIssues: 'Problemas',
  labelIssuesByLocation: 'Problemas por ubicación',
  labelIssuesOverTime: 'Problemas con el paso del tiempo',
  labelJobNumber: 'Número de trabajo',
  labelLastActivity: 'Última actividad',
  labelLastLogin: 'Último inicio de sesión',
  labelLastName: 'Apellido',
  labelLastUpdated: 'Última actualización',
  labelLatitude: 'Latitud',
  labelLongitude: 'Longitud',
  labelLocation: 'Ubicación',
  labelLocationCount: 'Conteo de ubicación',
  labelLocationGroup: 'Grupo de ubicación',
  labelLocationGroups: 'Grupos de ubicación',
  labelLocationSettings: 'Ajustes de ubicaciones',
  labelLocationSettingsDescription:
    'Controle el momento de registrar la ubicación de un usuario mediante los siguientes ajustes',
  labelLocations: 'Ubicaciones',
  labelLocationsReports: 'Informes de ubicaciones',
  labelLocked: 'Bloqueado',
  labelLoopExceptions: 'Excepciones de bucles',
  labelLoopTimes: 'Tiempos de bucle',
  labelLoops: 'Bucles',
  labelLowest: 'Más bajo',
  labelManually: 'Manualmente',
  labelMaps: 'Mapas',
  labelMedia: 'Medios',
  labelMessageGroups: 'Grupos de mensajes',
  labelMessages: 'Mensajes',
  labelMinutes: 'Minutos',
  labelMobile: 'Móvil',
  labelMobileAndWeb: 'Móvil y Web',
  labelMobileOnly: 'Únicamente móvil',
  labelMonths: 'Meses',
  labelMultiLine: '¿Multi línea?',
  labelMultiSelectList: 'Lista de selección multiple',
  labelName: 'Nombre',
  labelNewAndUpdatedIssuesAssignedToMe:
    'Problemas nuevos y actualizados, asignados a mí',
  labelNewArea: 'NUEVA Área',
  labelNewAudit: 'Nueva auditoría',
  labelNewCustomEntry: 'Nueva entrada de {{template}}',
  labelNewGroup: 'Nuevo grupo',
  labelNewIssue: 'Nuevo problemas, no asignados a mí',
  labelNewLoopTimes: 'Nuevos tiempos de bucle',
  labelNewPassword: 'Nueva contraseña',
  labelNewRole: 'Nueva función',
  labelNewTask: 'Nueva tarea',
  labelNewTemplate: 'Nueva plantilla',
  labelNewTemplateEntry: 'Nueva entrada de plantilla',
  labelNewUser: 'Nuevo usuario',
  labelNfc: 'NFC',
  labelNo: 'No',
  labelNoEndDate: 'No hay fecha de finalización',
  labelNoMessagesYet: 'No han llegado mensajes aún... ',
  labelNoRepeat: 'No hay repeticiones',
  labelNotRepeatable: 'No repetible',
  labelNotification: 'Notificación',
  labelNotificationPreferences: 'Preferencias de notificaciones',
  labelNumber: 'Número',
  labelOff: 'Apagado',
  labelOn: 'Encendido',
  labelOnline: 'En línea',
  labelOffline: 'Desconectado',
  labelOpen: 'Abierto',
  labelOpenTime: 'Tiempo abierto',
  labelOpened: 'Abierto',
  labelOptional: 'Opcional',
  labelOptions: 'Opciones',
  labelOverallScore: 'Puntaje general:',
  labelOverrideRoleRestrictions: 'Anular restricciones de roles',
  labelParticipants: 'Participantes',
  labelPassword: 'Contraseña',
  labelPendingUser: 'Usuario pendiente',
  labelPlatformAccess: 'Acceso a la plataforma',
  labelPostCode: 'Código postal',
  labelProfile: 'Perfil',
  labelPush: 'Push',
  labelQrCode: 'Código QR',
  labelQrCodes: 'Códigos QR',
  labelQuestion: 'Pregunta',
  labelQuestions: 'Preguntas',
  labelRadioButtons: 'Botones de opción',
  labelRaisedBy: 'Planteado por',
  labelRecentIssues: 'problemas recientes',
  labelRecentTasks: 'tareas recientes',
  labelReference: 'Referencia',
  labelRelationship: 'Relación',
  labelRepeat: 'Repetir',
  labelRepeatFrequency: 'Frecuencia de repetición',
  labelRepeatOn: 'Repetir el',
  labelRepeatOnSetTimeIntervals: 'Repetir en intervalos de tiempo establecidos',
  labelRepeatOnTimeSinceLastActivityCompletion:
    'Repetir a tiempo desde la finalización de la última actividad',
  labelRepeatable: 'Repetible',
  labelReportsDashboard: 'Tablero de repeticiones',
  labelRequired: '¿Es requerido?',
  labelRequiredField: 'requerido',
  labelRestrictIssueClosing: 'Restricción de cierre de incidencias',
  labelRole: 'Función',
  labelRoles: 'Funciones',
  labelRolesAndPermissions: 'Funciones y permisos',
  labelSMS: 'SMS',
  labelSchedule: 'Programa',
  labelScheduleWork: 'Programar trabajo',
  labelScheduledWork: 'Trabajo programado',
  labelScore: 'Calificación',
  labelSection: 'Sección',
  labelSelect: 'Seleccionar',
  labelSendAfter: 'Enviar después de',
  labelServiceHours: 'Horas de servicio',
  labelServiceLevel: 'Nivel de servicio',
  labelSetup: 'Configuración',
  labelShare: 'Compartir formulario PDF',
  labelShiftFinished: 'Turno finalizado',
  labelShiftStarted: 'Turno comenzado',
  labelShiftStatus: 'Condición',
  labelShifts: 'Turnos',
  labelSignal: 'Señal',
  labelSignals: 'Señales',
  labelSignature: 'Firma',
  labelSingleSelectList: 'Seleccionar lista',
  labelSkipLocationsWithNoActivity: 'Omitir ubicaciones sin actividad',
  labelSpanishLa: 'Español (latinoamerica)',
  labelSpanishLaShort: 'Español (LA)',
  labelSpecificActions: 'Acciones específicas',
  labelStarRating: 'Calificación estrella',
  labelStart: 'Comenzar',
  labelStartEndTime: 'Hora de inicio y finalización',
  labelStartLocation: 'Ubicación de inicio',
  labelStartShiftRestriction: 'Restricciones de turno de inicio',
  labelStartSchedule: 'Comenzar programa',
  labelStartShift: 'Comenzar turno',
  labelStartTime: 'Hora de inicio',
  labelState: 'Estado',
  labelStatus: 'Condición',
  labelStreet: 'Calle',
  labelSummaryField: 'Campo de resumen',
  labelSummaryReport: 'Informe de resumen',
  labelSummaryReportFormSubmissions:
    'Informe de resumen + envío de formularios',
  labelSwitch: 'Cambiar',
  labelTarget: 'Objetivo',
  labelTargetParcentage: 'Objetivo (%)',
  labelAboveTarget: 'Por encima del objetivo',
  labelBelowTarget: 'Por debajo del objetivo',
  labelNoTarget: 'Sin objetivo',
  labelNoAccess: 'Sin acceso',
  labelOnTarget: 'En el blanco', // TODO: Get a better translation?
  labelTask: 'Tarea',
  labelTaskTemplates: 'Plantillas de tareas',
  labelTasks: 'Tareas',
  labelTemplate: 'Plantilla',
  labelTemplates: 'Plantillas',
  labelText: 'Texto',
  labelTime: 'Hora',
  labelTimegate: 'Timegate',
  labelTempla: 'Templa',
  labelTimegateSIN: 'SIN',
  labelTimegateEmployeePIN: 'PIN de Timegate',
  labelTimeMins: 'Hora (min)',
  labelTimeline: 'Cronología',
  labelTimestamp: 'Marca de tiempo',
  labelTimezone: 'Zona horaria',
  labelTitle: 'Título',
  labelTitleDateRange: '{{title}} desde {{from}} hasta {{to}}',
  labelTo: 'Para',
  labelType: 'Tipo',
  labelUnknownLocation: 'Ubicación desconocida',
  labelUnlimited: 'Ilimitado',
  labelUpdatedAudit: 'Auditoría actualizada',
  labelUpdatedIssue: 'Problemas actualizados, no asignados a mí',
  labelUpdatedTask: 'Tarea actualizada',
  labelUnknownArea: 'Área desconocida',
  labelUnknownLocationGroup: 'Grupo de ubicación desconocido',
  labelUnknownSignal: 'Señal desconocida',
  labelUseLocationServiceHours: 'Use las horas de servicio de la ubicación',
  labelUser: 'Usuario',
  labelUserRoles: 'Roles de usuario',
  labelUserCanSkip: 'El usuario puede omitir',
  labelUserId: 'Identificación de usuario',
  labelUsername: 'Nombre de usuario',
  labelUsers: 'Usuarios',
  labelView: 'Visualizar',
  labelVisit: 'Visita',
  labelVisits: 'Visitas',
  labelWebOnly: 'Solo web',
  labelWeeks: 'Semanas',
  labelWeight: 'Peso',
  labelWinTeam: 'WinTeam',
  labelWinTeamEnabled: 'WinTeam habilitado',
  labelXLocations: '{{ count }} Ubicaciones',
  labelXLocationGroups: '{{ count }} Grupos de Ubicación',
  labelYears: 'Años',
  labelYes: 'Sí',
  labelZeroLocations: '0 Ubicaciones',
  labelZone: 'Zona',

  'leftPanel.locationGroupView.title':
    'Seleccione un nuevo grupo de ubicación para asignarlo a la ubicación:',

  'locationGroup.error.addLocations':
    'No se pudieron agregar {{errorCount}} ubicaciones',
  'locationGroup.error.location': 'Error de ubicación',
  'locationGroup.error.locationDeleted': 'Esta ubicación ha sido borrada',
  'locationGroup.error.locationDeletedTitle': 'Ubicación borrada',
  'locationGroup.error.locationAtMaxGroups':
    'Esta ubicación no se puede agregar a más grupos.',
  'locationGroup.error.locationAtMaxGroupsTitle': 'Límite de ubicación',
  'locationGroup.error.groupAtMaxLocations':
    'Este grupo no puede contener más ubicaciones.',
  'locationGroup.error.groupAtMaxLocationsTitle': 'Límite de grupo',
  'locationGroup.crud.tooltip.message':
    'Los cambios en el grupo de ubicación se aplicarán únicamente a envíos de formularios futuros. Por ejemplo, las tareas enviadas en la misma ubicación solo se mostrarán después de que la ubicación se agregue al grupo de ubicaciones.',
  'locationGroup.crud.tooltip.title': 'Guardar grupos de ubicaciones',

  'locationPanel.filter.allLocations': 'Todas las ubicaciones',
  'locationPanel.filter.closed': 'Cerrado',
  'locationPanel.filter.open': 'Abierto',
  'locationPanel.filter.openActive': 'Abierto / Activo',
  'locationPanel.filter.openInactive': 'Abierto / Inactivo',
  'locationPanel.sort.locationName': 'Nombre de la ubicación',
  'locationPanel.sort.userCount': 'Número de usuarios',
  'locationPanel.status.closed': 'CERRADO',
  'locationPanel.status.open': 'ABIERTO',
  'locationPanel.userCount': '{{count}} usuario en línea',
  'locationPanel.userCount_plural': '{{count}} usuarios en línea',

  'login.applicationIdTooltip.message':
    'Su IDentificación (ID) de la aplicación es su único IDentificador para la aplicación, consiste de una sola palabra en minúsculas y sin espacios; ej. "nombre-de-compañía". Si no recuerda su ID de la aplicación, póngase en contacto con support@lighthouse.io.',
  'login.applicationIdTooltip.title': 'ID de la aplicación',
  'login.error.noEmailAddress': 'Ingrese su dirección de e-mail',
  'login.footerMessage': '¿Es nuevo en Lighthouse.io? ',
  'login.header.applicationSignIn': 'Inicie sesión en su aplicación',
  'login.header.forgotPassword': 'Reestablezca su contraseña',
  'login.header.signIn': 'Inicie sesión',
  'login.requestAccount': 'Solicite una cuenta',
  'login.success.resetPassword':
    'Su contraseña ha sido restablecida. Siga las indicaciones en el correo que le hemos enviado a {{username}}',
  'login.whatIsMyApplicationId': '¿Cuál es mi ID de la aplicación?',
  'logout.header.loggingOut': 'Cerrando sesión',
  'location.serviceHoursTooltip.message': `Configurarlos es útil para fines de monitoreo, ya que determina cuándo se espera actividad en esta ubicación (por ejemplo, para horarios)..`,
  'location.serviceHoursTooltip.title': `Horas de Servicio`,

  'map.tooltip.clickToPlace': 'Haga clic para ubicar en el mapa',
  'map.tooltip.invalidText': 'Continúe dibujando',
  'map.tooltip.new.auditentries':
    'Nueva auditoría (haga clic para reposicionar)',
  'map.tooltip.new.issue': 'Nuevo problema (haga clic para reposicionar)',
  'map.tooltip.new.signal': 'Nueva señal (haga clic para reposicionar)',
  'map.tooltip.new.taskentries': 'Nueva tarea (haga clic para reposicionar)',
  'map.tooltip.outsideLocation':
    'Debe encontrarse dentro de la demarcación matriz',
  'map.tooltip.startText': 'Haga clic en el mapa para comenzar a dibujar',
  'map.tooltip.validText': 'Haga clic en el primer punto para finalizar',
  'mapOptions.legend': 'Leyenda',
  'mapOptions.legendItem.active': 'Activo (<5m)',
  'mapOptions.legendItem.activeUserCount': 'Número de usuarios activos',
  'mapOptions.legendItem.audit': 'Auditar',
  'mapOptions.legendItem.clusterWithLocation': 'Agrupación con ubicación',
  'mapOptions.legendItem.clusterWithoutLocation': 'Agrupación sin ubicación',
  'mapOptions.legendItem.inactive': 'Inactivo (>1h)',
  'mapOptions.legendItem.issue': 'Problema',
  'mapOptions.legendItem.recent': 'Reciente (1h)',
  'mapOptions.legendItem.task': 'Tarea',
  'mapOptions.legendItem.userInVehicle': 'Usuario en vehículo',
  'mapOptions.newAudit': 'Nueva auditoría',
  'mapOptions.newIssue': 'Nuevo problema',
  'mapOptions.newLocation': 'Nueva ubicación',
  'mapOptions.newTask': 'Nueva tarea',
  'mapOptions.options': 'Opciones',
  'mapOptions.optionsItem.audits': 'Auditoría',
  'mapOptions.optionsItem.geofences': 'Geocercas',
  'mapOptions.optionsItem.issues': 'Problemas',
  'mapOptions.optionsItem.locations': 'Ubicaciones',
  'mapOptions.optionsItem.map': 'Mapa',
  'mapOptions.optionsItem.people': 'Personas',
  'mapOptions.optionsItem.satellite': 'Satélite',
  'mapOptions.optionsItem.signals': 'Señales',
  'mapOptions.optionsItem.tasks': 'Tareas',
  'mapOptions.optionsItem.tooltips': 'Consejos de herramientas',
  'mapOptions.optionsItem.beacon': 'Balizas',
  'mapOptions.optionsItem.qrcode': 'QR',
  'mapOptions.optionsItem.nfc': 'NFC',

  'modal.delete.area.title': '¿Eliminar esta área?',
  'modal.delete.area.message':
    '¿Está seguro de que desea eliminar esta área?\n\nAl eliminar este piso, se eliminarán todas las áreas y señales contenidas en esta área. Esta acción no se puede revertir.',
  'modal.delete.audit.title': '¿Eliminar esta auditoría?',
  'modal.delete.audit.message':
    '¿Está seguro de que desea eliminar esta auditoría?\n\nAl eliminar la auditoría, se elimina el envío. Esta acción no se puede revertir.',
  'modal.delete.building.title': '¿Eliminar este edificio?',
  'modal.delete.building.message':
    '¿Está seguro de que desea eliminar este edificio?\n\nAl eliminar este edificio, se eliminarán todas las áreas, pisos y señales contenidas en este edificio. Esta acción no se puede revertir.',
  'modal.delete.floor.title': '¿Eliminar este piso?',
  'modal.delete.floor.message':
    '¿Está seguro de que desea eliminar este piso?\n\nAl eliminar este piso, se eliminarán todas las áreas y señales contenidas en este piso. Esta acción no se puede revertir.',
  'modal.delete.issue.title': '¿Eliminar este problema?',
  'modal.delete.issue.message':
    '¿Está seguro de que desea eliminar este problema?\n\nAl eliminar el problema, se elimina el envío. Esta acción no se puede revertir.',
  'modal.delete.location.title': '¿Eliminar esta ubicación?',
  'modal.delete.location.message':
    '¿Está seguro de que desea eliminar esta ubicación?\n\nAl eliminar esta ubicación, se eliminarán todas las áreas y señales contenidas en esta ubicación. Esta acción no se puede revertir.',
  'modal.delete.locationGroup.title': '¿Eliminar este grupo de ubicaciones?',
  'modal.delete.locationGroup.message':
    '¿Está seguro de que desea eliminar este grupo de ubicaciones?\n\nAl eliminar el grupo de ubicaciones, se elimina la capacidad de filtrar plantillas, filtrar informes y asignar el grupo a plantillas. No elimina las ubicaciones ni las elimina de otros grupos de ubicaciones.',
  'modal.delete.messageGroup.title': '¿Eliminar este grupo de mensajes?',
  'modal.delete.messageGroup.message':
    '¿Está seguro de que desea eliminar este grupo de mensajes?',
  'modal.delete.role.title': '¿Eliminar esta función?',
  'modal.delete.role.message':
    '¿Está seguro de que desea eliminar este rol?\n\nLos roles no se pueden eliminar mientras haya usuarios activos asignados. Eliminar el rol elimina la capacidad de filtrar informes por este rol. Eliminar el rol no elimina los datos enviados previamente.',
  'modal.delete.schedule.title': '¿Eliminar este horario?',
  'modal.delete.schedule.message':
    '¿Está seguro de que desea eliminar este programa?\n\nAl eliminar el programa, se eliminarán todas las instancias activas y futuras de este programa.',
  'modal.delete.signal.title': '¿Eliminar esta señal?',
  'modal.delete.signal.message':
    '¿Está seguro de que desea eliminar este programa?\n\nAl eliminar el programa, se eliminarán todas las instancias activas y futuras de este programa.',
  'modal.delete.signals.title': '¿Eliminar estas señales?',
  'modal.delete.signals.message':
    '¿Está seguro de que desea eliminar estas señales?\n\nAl eliminar estas señales, se eliminarán los códigos QR o ID de etiqueta NFC asociados (según corresponda). Los códigos QR no se pueden reutilizar una vez eliminados. Las etiquetas NFC se pueden reasignar a nuevas señales según sea necesario.',
  'modal.delete.task.title': '¿Eliminar esta tarea?',
  'modal.delete.task.message':
    '¿Está seguro de que desea eliminar esta tarea?\n\nAl eliminar la tarea, se elimina el envío. Esta acción no se puede revertir.',
  'modal.delete.template.title': '¿Eliminar esta plantilla?',
  'modal.delete.template.message':
    '¿Está seguro de que desea eliminar esta plantilla?inAl eliminar la plantilla, se elimina la posibilidad de filtrar los informes por esta plantilla. Eliminar la plantilla no elimina los datos enviados previamente.',
  'modal.delete.user.title': '¿Eliminar este usuario?',
  'modal.delete.user.message':
    '¿Está seguro de que desea eliminar este usuario?\n\nAl eliminar el usuario, se elimina la capacidad de filtrar los informes de este usuario. Eliminar el usuario no elimina los datos enviados previamente.',

  'navigation.admin': 'Admin',
  'navigation.admin.applications': 'Aplicaciones',
  'navigation.logout': 'Cerrar sesión',
  'navigation.maps': 'Mapas',
  'navigation.messages': 'Mensajes',
  'navigation.reports': 'Informes',
  'navigation.reports.audits': 'Auditorías',
  'navigation.reports.dashboard': 'Tablero',
  'navigation.reports.events': 'Eventos',
  'navigation.reports.exceptions': 'Excepciones',
  'navigation.reports.exports': 'Exportaciones',
  'navigation.reports.issues': 'Problemas',
  'navigation.reports.locations': 'Ubicaciones',
  'navigation.reports.shifts': 'Turnos',
  'navigation.reports.tasks': 'Tareas',
  'navigation.schedules': 'Programas',
  'navigation.schedules.work': 'Trabajo',
  'navigation.settings': 'Ajustes',
  'navigation.settings.profile': 'Perfil',
  'navigation.settings.roles': 'Funciones y permisos',
  'navigation.settings.users': 'Usuarios',
  'navigation.setup': 'Configuración',
  'navigation.setup.locations': 'Ubicaciones',
  'navigation.setup.location-groups': 'Grupos de ubicación',
  'navigation.setup.loops': 'Tiempos de bucle',
  'navigation.setup.signals': 'Señales',
  'navigation.templates': 'Plantillas',
  'navigation.templates.audits': 'Auditorías',
  'navigation.templates.content': 'Plantillas de contenido',
  'navigation.templates.entries': 'Entradas de contenido',
  'navigation.templates.issues': 'Problemas',
  'navigation.templates.tasks': 'Tareas',

  'panel.header.loadingTemplates': 'Cargando plantillas',
  'panel.header.selectTemplate': 'Seleccione una plantilla',

  'placeholder.NfcTagId': 'ID de etiqueta NFC',
  'placeholder.advertisingInterval': 'Intervalo de publicidad',
  'placeholder.allLocations': 'Todas las ubicaciones',
  'placeholder.allZones': 'Todas las zonas',
  'placeholder.auditTitle': 'Título de la auditoría',
  'placeholder.beaconMajor': 'Beacon Importante',
  'placeholder.beaconMinor': 'Beacon Menor',
  'placeholder.chooseAGroupName': 'Elija un nombre de grupo',
  'placeholder.chooseOptionOrAddNewOption':
    'Elija una opción o agregue una nueva opción',
  'placeholder.city': 'Ciudad',
  'placeholder.description': 'Descripción',
  'placeholder.email': 'correo electrónico',
  'placeholder.enterApplicationId': 'Ingrese la ID de la aplicación',
  'placeholder.enterMessage': 'Ingrese mensaje... ',
  'placeholder.enterMobileNumber': 'Ingrese el número de celular',
  'placeholder.firstName': 'primer nombre',
  'placeholder.floors': 'Pisos',
  'placeholder.issueTitle': 'Título del problema',
  'placeholder.label': 'Etiqueta',
  'placeholder.lastName': 'apellido',
  'placeholder.latitude': 'Latitud',
  'placeholder.longitude': 'Longitud',
  'placeholder.mins': 'Mins',
  'placeholder.mobile': 'móvil',
  'placeholder.name': 'Nombre',
  'placeholder.nameOfLocationGroup': 'Nombre del grupo de ubicación',
  'placeholder.password': 'contraseña',
  'placeholder.postCode': 'Código postal',
  'placeholder.reference': 'Referencia',
  'placeholder.roleName': 'Nombre de la función',
  'placeholder.rssi': 'RSSI',
  'placeholder.search': 'Buscar',
  'placeholder.searchLocations': 'Buscar ubicaciones',
  'placeholder.searchOrSelect': 'Buscar o seleccionar',
  'placeholder.search_ellipsis': 'Buscar...',
  'placeholder.select': 'Seleccionar',
  'placeholder.selectANotificationMethod':
    'Seleccionar un método de notificación',
  'placeholder.selectAccess': 'Seleccionar acceso',
  'placeholder.selectAnOption': 'Seleccione una opción',
  'placeholder.selectArea': 'Seleccionar un área',
  'placeholder.selectAreas': 'Seleccionar áreas',
  'placeholder.selectAssignedUsers': 'Seleccionar usuarios asignados',
  'placeholder.selectAuditTemplate': 'Seleccionar plantilla de auditoría',
  'placeholder.selectCountry': 'Seleccionar país',
  'placeholder.selectDateFormat': 'Seleccionar formato de fecha',
  'placeholder.selectDates': 'Seleccionar fechas',
  'placeholder.selectFilterCollection': 'Seleccionar colección de filtros',
  'placeholder.selectFilters': 'Seleccionar filtros',
  'placeholder.selectIssueStatus': 'Seleccionar condición del problema',
  'placeholder.selectIssueTemplate': 'Seleccionar plantilla del problema',
  'placeholder.selectLanguage': 'Seleccionar idioma',
  'placeholder.selectLocation': 'Seleccionar ubicación',
  'placeholder.selectLocationGroup': 'Seleccionar grupo de ubicación',
  'placeholder.selectLocations': 'Seleccionar ubicaciones',
  'placeholder.selectLocationsLocationGroups':
    'Seleccionar ubicación (máx. 1000)',
  'placeholder.selectOption': 'Seleccionar opción',
  'placeholder.selectOrDropPin': 'Seleccionar o soltar pin',
  'placeholder.selectOrSearch': 'Seleccionar o Buscar',
  'placeholder.selectParticipants': 'Seleccionar participantes',
  'placeholder.selectRole': 'Seleccionar función',
  'placeholder.selectRoles': 'Seleccionar funciones',
  'placeholder.selectShiftType': 'Seleccionar tipo de turno',
  'placeholder.selectTaskTemplate': 'Seleccionar plantilla de tarea',
  'placeholder.selectTemplate': 'Seleccionar plantilla',
  'placeholder.selectTime': 'Seleccionar hora',
  'placeholder.selectTimeFormat': 'Seleccionar formato de hora',
  'placeholder.selectTimezone': 'Seleccionar zona horaria',
  'placeholder.selectType': 'Seleccionar tipo',
  'placeholder.selectUser': 'Seleccionar usuario',
  'placeholder.selectUserRoles': 'Seleccionar roles de usuario',
  'placeholder.selectUsers': 'Seleccionar usuarios',
  'placeholder.signalLabel': 'Etiqueta de señal',
  'placeholder.signalType': 'Tipo de señal',
  'placeholder.state': 'Estado',
  'placeholder.streetAddress': 'Dirección física',
  'placeholder.target': 'Objetivo',
  'placeholder.taskTitle': 'Título de la tarea',
  'placeholder.timegateEmployeePIN': 'PIN',
  'placeholder.transmittingPower': 'Potencia de transmisión del',
  'placeholder.username': 'nombre de usuario',
  'placeholder.uuid': 'UUID',
  'placeholder.winTeam': 'WinTeam',

  privacy: 'Privacidad',

  'prompts.error.applicationError':
    'Error de aplicación. Póngase en contacto con soporte técnico',
  'prompts.error.fieldToComplete': 'Tiene  {{count}} campo por completar',
  'prompts.error.fieldToComplete_plural':
    'Tiene {{count}} campos por completar',
  'prompts.error.pleaseCompleteAllFields': 'Complete todos los campos',
  'prompts.error.validation': 'Tiene {{count}} error de validación',
  'prompts.error.validation_plural': 'Tiene {{count}} errores de validación',
  'prompts.unsaved.youHaveUnsavedChanges': 'Tiene cambios no guardados',
  'prompts.warning.resolveWarnings': 'Debe resolver estas advertencias',

  'roles.adhocTooltip.message':
    'Registre la ubicación cuando los usuarios toman acciones específicas usando la aplicación móvil. Estas acciones incluyen, comenzar/finalizar turno, lectura de código QR, remisión de tareas, remisión de problemas, y remisión de auditorías.',
  'roles.adhocTooltip.title': 'Registre la ubicación durante las acciones',
  'roles.passiveTooltip.message':
    'Registre continuamente la ubicación en segundo plano, incluso cuando la aplicación móvil se haya cerrado',
  'roles.passiveTooltip.title': 'Siempre registre la ubicación',

  'schedules.locationsTooltip.title': 'Seleccionar ubicaciones',
  'schedules.locationsTooltip.message': `Asigne a la(s) ubicación(es) apropiada(s). Si selecciona varias ubicaciones, no podrá asignarlas también a Señales y áreas.`,
  'schedules.areasTooltip.title': 'Seleccionar área(s) y señal(es)',
  'schedules.areasTooltip.message': `Asigne a las Áreas y Señales apropiadas.`,
  'schedules.enableTooltip.title': 'Opción Habilitar programación',
  'schedules.enableTooltip.message': `Desplegable Habilitado hasta Sí. Si este campo se establece en No, este elemento de trabajo programado no estará activo.`,
  'schedules.usersTooltip.title': 'Seleccionar Usuario(s)',
  'schedules.usersTooltip.message': `Asignar a los Usuarios apropiados. Si la programación se asigna a varios usuarios, solo debe completarla uno de esos usuarios.`,
  'schedules.repeatTooltip.title': 'Seleccione la opción Repetir',
  'schedules.repeatTooltip.message': `La opción <i>No repetir</i> es una ocurrencia única y por defecto usa las horas de servicio de ubicación.<br/>La opción <i>Repetir a tiempo desde la finalización de la última actividad</i> basa el temporizador de inicio en la hora en que se completó la actividad.<br/>La opción <i>Repetir en intervalos de tiempo establecidos</i> basa la hora de inicio en intervalos regulares a partir de la hora de servicio abierto de la ubicación.`,
  'schedules.rolesTooltip.title': 'Seleccionar Rol(es)',
  'schedules.rolesTooltip.message': `Asigne a los roles apropiados. Si la programación se asigna a varios roles, solo debe completarla uno de esos usuarios.`,
  'schedules.typeTooltip.title': 'Tipo de Horario',
  'schedules.typeTooltip.message': `Seleccione el menú desplegable Tipo y seleccione Visita, Auditoría o Tarea. La visita requiere un evento de ubicación para completarse, mientras que la Auditoría/Tarea requiere el envío de la Auditoría/Tarea seleccionada.`,
  'schedule.startTooltip.message': `Establezca la fecha de programación de inicio, en función de cuándo desea que comience este elemento. Tenga en cuenta que si selecciona Hoy, la programación comenzará exactamente a la hora enviada (por ejemplo, a la 1 p. m. si se guarda a la 1 p. m.). Si desea que los horarios comiencen al comienzo del día, seleccione una fecha futura (p. ej., mañana).
  Si hay horas específicas para completar este trabajo, desmarque la casilla de verificación Usar horas de servicio de ubicación, haga clic en el botón Agregar horas de servicio y configure las opciones Día/Fecha, Opciones, Hora de apertura y Hora de cierre.`,
  'schedule.startTooltip.title': 'Seleccione la opción de horario de inicio',

  'searchMenu.footerMessage':
    'Busque y seleccione por nombre de ubicación o dirección',
  'searchMenu.header.recent': 'Reciente',
  'searchMenu.noRecentLocations': 'No hay ubicaciones recientes',
  'searchMenu.noResultsFound': 'No se encontraron ubicaciones',

  'searchPanel.noSearch':
    'Ingrese el texto de búsqueda para obtener resultados',
  'searchPanel.userLastSeen': 'Visto por última vez - {{datetime}}',

  'setup.bulkSelect':
    'Haga clic para seleccionar todas las señales que se muestran en la página.',
  'setup.clearSelection': 'Selección clara.',
  'setup.selectMessage': '{{selectedCount}} de {{totalCount}} seleccionado',

  switchApplication: 'Cambiar aplicación',

  'taskPanel.header.newTask': 'Nueva tarea',
  'taskPanel.header.task': 'Tarea',
  'template.fieldReference.message':
    'Un valor para usar como referencia para exportaciones o enlace a un sistema externo.',
  'template.fieldReference.title': 'Referencia',
  'template.fieldTooltip.message':
    'Un campo representa una sola entrada en su formulario',
  'template.fieldTooltip.title': 'Campo',
  'template.groupNameTooltip.message':
    'Un grupo es un mecanismo organizativo para agrupar conjuntos de campos de forma',
  'template.groupNameTooltip.title': 'Nombre de grupo',
  'template.locationGroup.title': 'Asignación de grupos de ubicación',
  'template.locationGroup.message': `Las asignaciones de grupos de ubicación se aplicarán únicamente a envíos de formularios futuros. Por ejemplo, las tareas enviadas en la misma ubicación solo se mostrarán en un grupo de ubicaciones después de agregar la plantilla.`,
  'template.requiredTooltip.message':
    'Sí - La pregunta requiere una respuesta. El formulario no se enviará si contiene preguntas no respondidas. No - El usuario puede omitir la pregunta. Las preguntas omitidas no afectarán la calificación de la auditoría',
  'template.requiredTooltip.title': 'Obligatorio',
  'template.repeatTooltip.message':
    'Cuando la opción de grupo repetible está habilitada en una plantilla de formulario, permite que el empleado que completa el formulario repita el grupo de formularios más de una vez.',
  'template.repeatTooltip.title': 'Seleccione Repetible',
  'template.scoreTooltip.message':
    'El valor de la calificación representa el resultado de la pregunta dentro de su propio contexto. Veámoslo como una calificación porcentual en la escala de 0 al 1, en la que 1 representa Aprobado, 0.5 representa Regular, y 0 representa Reprobado',
  'template.scoreTooltip.title': 'Calificación',
  'template.summaryFieldTooltip.message':
    'Los campos de resumen le permiten mostrar información adicional en los resúmenes de actividades. Puede seleccionar hasta {{MAX_SUMMARY_FIELDS}} campos de resumen por cada plantilla.',
  'template.summaryFieldTooltip.title': 'Campo de resumen',
  'template.typeTooltip.message':
    "Le permite especificar qué tipo de entrada se mostrará. Texto permite una introducción simple de texto. Seleccionar permite múltiples opciones de introducción predefinida. Cambiar permite introducción en casillas de verificación. Medios permite la carga de medios.'",
  'template.typeTooltip.title': 'Tipo',
  'template.weightTooltip.message':
    'El valor ponderado representa la comparación de la calificación de esta pregunta con las de las demás preguntas. Debe ser un valor numérico positivo. Si la pregunta tiene más relevancia que otras, otórguele un mayor valor. Para obtener un total global, se multiplica el valor con la calificación para la pregunta al momento de remitir la auditoría.',
  'template.weightTooltip.title': 'Peso',
  'template.statusBanner.locked':
    'Esta plantilla está actualmente bloqueada y nadie puede editarla',
  'template.statusBanner.unlocked':
    'Esta plantilla actualmente está desbloqueada y puede ser editable por cualquier persona',
  'template.statusBanner.lockButtonLock': 'Cerrar',
  'template.statusBanner.lockButtonUnlock': 'Desbloquear',
  'template.signalsAssignmentBanner': `Asigne señales a esta plantilla. Cuando los usuarios de la aplicación escanean un código QR o una etiqueta NFC asociada con una señal, esta plantilla aparecerá si se asigna a continuación.`,

  terms: 'Términos',
  textAbilityToCreateMessageGroups: 'Capacidad para crear grupos de mensajes',
  textAbilityToGenerateDownloadDataExports:
    'Capacidad para generar y descargar exportaciones de datos',
  textAbilityToManageFormTemplates:
    'Capacidad para ver y administrar plantillas de formulario',
  textAbilityToManageLoopTimes:
    'Capacidad para ver y administrar tiempos de bucle. El acceso a los tiempos de bucle individuales se define a nivel de usuario',
  textAbilityToOverrideRoleRestrictions:
    'Capacidad para ver todos los documentos con funciones restringidas',
  textAbilityToRestrictIssueClosing:
    'Evitar la capacidad de cambiar el estado del problema a "cerrado"',
  textAbilityToSetupPages:
    'Posibilidad de ver la configuración y las páginas asociadas',
  textAbilityToShareForms:
    'Capacidad para compartir formularios desde el móvil',
  textAbilityToViewApplicationRoles:
    'Capacidad para ver los roles de la aplicación y agregar / editar / eliminar roles y permisos',
  textAbilityToViewApplicationUser:
    'Posibilidad de ver al usuario de la aplicación y agregar / editar / eliminar usuarios',
  textAbilityToViewCreateEditLocations:
    'Capacidad para ver, crear y editar ubicaciones. El acceso a ubicaciones individuales se asigna a nivel de usuario.',
  textAbilityToViewCreateEditSignals:
    'Capacidad para ver, crear y editar señales, incluida la asignación de señales desde la aplicación móvil',
  textAbilityToViewCreateEditWork:
    'Capacidad para ver, crear y editar el trabajo programado',
  textAbilityToViewDailyLocationReports:
    'Posibilidad de ver los informes de ubicación diarios',
  textAbilityToViewExceptionsTriggered:
    'Posibilidad de ver las excepciones activadas',
  textAbilityToViewManage: 'Capacidad para ver y administrar',
  textAbilityToViewManageEvents: 'Capacidad para ver y administrar eventos',
  textAbilityToViewMapTimeline:
    'Capacidad para ver la línea de tiempo del mapa y los datos de ubicación históricos',
  textAbilityToViewMaps: 'Posibilidad de ver mapas',
  textAbilityToViewRecentActivity:
    'Posibilidad de ver el feed de actividad reciente',
  textAbilityToViewReportsDashboard: 'Posibilidad de ver el panel de informes.',
  textAbilityToViewSendMessages: 'Posibilidad de ver y enviar mensajes.',
  textAbilityToViewSignalTesting: 'Posibilidad de ver pruebas de señal.',
  textShiftsPermissionDescription:
    'Capacidad para ver los turnos enviados en la web y crear turnos en dispositivos móviles',
  textAllAudits: '...todas las auditorías',
  textAllIssues: '...todos los problemas',
  textAllTasks: '...todas las tareas',
  textAssignedTo: '/asignado a',
  textCreateYourFirstGroup: 'Crea tu primer grupo',
  textDoNotBelongToAnyMessageGroups:
    'Actualmente no pertenece a ningún grupo de mensajes.',
  textLoading: 'Sobreprima...',
  textNoResults: 'No hay resultados',
  textNow: 'ahora',
  textOnlyAudits:
    '...solo auditorías creadas por{{assigneesDescription}} este usuario',
  textOnlyIssues:
    '...solo problemas creados por{{assigneesDescription}} este usuario',
  textOnlyTasks:
    '...solo tareas creadas por{{assigneesDescription}} este usuario',
  textResendAllInvites: 'Reenviar invitaciones',
  textResendAllInvitesConfirm:
    'Esto reenviará las invitaciones a todos los usuarios que actualmente tienen invitaciones pendientes. ¿Está seguro de que desea reenviar invitaciones a {{invitedUsersCount}} usuarios?',
  textResendAllInvitesSuccess:
    'Las invitaciones se están reenviando. Esto puede tardar unos minutos en completarse.',
  textResendAllInvitesError:
    'Ocurrió un problema con el reenvío de invitaciones. Inténtalo de nuevo.',
  textResendAllInvitesCooldownHoursAndMinutes:
    'El envío de invitaciones a todos los usuarios con una invitación pendiente se podrá realizar una vez cada 8 horas. Esta acción se puede repetir en {{hoursRemaining}} horas y {{minutesRemaining}} minutos.',
  textResendAllInvitesCooldownMinutes:
    'El envío de invitaciones a todos los usuarios con una invitación pendiente se podrá realizar una vez cada 8 horas. Esta acción se puede repetir en {{minutesRemaining}} minutos.',
  textUnknown: 'Desconocido',

  titleAddUsers: 'Agregar usuarios',
  titleGeoSettings: 'Configuración geográfica',
  titleNewSignal: 'Nueva señal',
  titleNewUsers: 'Nuevos usuarios',

  'tooltip.message.Uuid':
    'Una cadena UUID Spec v4 utilizada para diferenciar un gran grupo de balizas relacionadas.',
  'tooltip.message.advertisingInterval':
    'El retraso entre los paquetes publicitarios de difusión. Debe ser un número entero positivo.',
  'tooltip.message.beaconMajor':
    'Se utiliza para agrupar un conjunto relacionado de balizas (por ejemplo, todas las balizas en una única ubicación).',
  'tooltip.message.beaconMajorAlt':
    'Un número entero positivo que se utiliza para diferenciar un subconjunto más pequeño de balizas dentro del grupo más grande.',
  'tooltip.message.beaconMinor':
    'Se utiliza para identificar balizas individuales (por ejemplo, una sola zona en una sola ubicación).',
  'tooltip.message.beaconMinorAlt':
    'Un número entero positivo que se utiliza para identificar balizas individuales.',
  'tooltip.message.beaconUuid':
    'Identificador de baliza único que ayuda a la aplicación móvil a distinguir sus balizas de otras balizas. Debe usar el mismo UUID para todas las balizas asociadas con una aplicación en particular.',
  'tooltip.message.document': 'Tipos de archivos admitidos, PDF, DOC, DOCX',
  'tooltip.message.jobNumber':
    'Para los clientes de WinTeam, puede hacer referencia al número de trabajo relacionado',
  'tooltip.message.locationGroupsRestrictions':
    'Seleccione entre 0 y {{locationsPerGroupMax}} ubicaciones para cada grupo. Las ubicaciones individuales se pueden incluir en un máximo de {{groupsPerLocationMax}} grupos.',
  'tooltip.message.locationXRefCode':
    'Para los clientes de Dayforce, puede hacer referencia al código de ubicación relacionado',
  'tooltip.message.mapZoomLevel':
    'Representa el zoom predeterminado en el mapa o plano de planta. Un zoom de mapa estándar sería 17 o más.',
  'tooltip.message.media': 'Tipos de archivos admitidos, PNG, JPG, JPEG, MP4',
  'tooltip.message.nfcTagId':
    'Todas las etiquetas NFC se fabrican con un identificador de etiqueta único. Suelen ser 14 caracteres hexadecimales (p. Ej., 04 9C 64 D2 45 2B 80) y no se pueden modificar. Estos identificadores de etiqueta se asignan a la señal para identificar la etiqueta cuando la aplicación móvil Lighthouse los escanea.',
  'tooltip.message.photo': 'Tipos de archivos admitidos, PNG, JPG, JPEG, MP4',
  'tooltip.message.shiftGeofenceRestriction': `Confirmar ubicación: será necesario seleccionar una ubicación antes de que se pueda iniciar un turno. La ubicación del turno final heredará esta ubicación.

   Confirmar geocerca: requerirá que los usuarios estén dentro de la geocerca de una ubicación al comenzar un turno.
   
   Si ambos están habilitados, su GPS debe estar dentro de la geocerca de la ubicación seleccionada al inicio del turno.`,
  'tooltip.message.signalStrengthIndicator':
    'Un valor numérico que indica la fuerza de la señal de la baliza como se ve en el dispositivo receptor.',
  'tooltip.message.timezone':
    'La zona horaria es la zona horaria local de la ubicación seleccionada.',
  'tooltip.message.templateUserRoles':
    'Una vez asignados, los envíos futuros de formularios se restringirán a las funciones asignadas.\n\nLos usuarios con permisos de edición de plantillas tendrán acceso para enviar estos formularios en la web o en dispositivos móviles, pero no podrán ver los envíos.',
  'tooltip.message.transmittingPower':
    'La potencia con la que la señal sale de la antena de la baliza. Por lo general, entre -30 dBm y +4 dBm',
  'tooltip.message.timegateSIN':
    'Para los clientes de Timegate, puede hacer referencia al sitio relacionado utilizando el número de identificación del sitio de Timegate.',
  'tooltip.message.templaSiteCode':
    'Para los clientes de Templa, puede hacer referencia al sitio relacionado usando el código del sitio de Templa',
  'tooltip.reference.message':
    'Los campos de referencia son campos de texto libre, normalmente utilizados para admitir integraciones con soluciones externas. No aparecen dentro de la aplicación móvil, informes o exportaciones.',
  'tooltip.reference.title': 'Campo de referencia',
  'tooltip.title.Uuid': 'UUID',
  'tooltip.title.advertisingInterval': 'Intervalo de publicidad',
  'tooltip.title.apiError': 'Error de API',
  'tooltip.title.beaconMajor': 'Beacon Importante',
  'tooltip.title.beaconMinor': 'Beacon Menor',
  'tooltip.title.beaconUuid': 'Beacon UUID',
  'tooltip.title.document': 'Subir archivo',
  'tooltip.title.jobNumber': 'Número de trabajo de WinTeam',
  'tooltip.title.locationGroupsRestrictions':
    'Restricciones de grupo de ubicación',
  'tooltip.title.locationXRefCode': 'Código de ubicación de Dayforce',
  'tooltip.title.mapZoomLevel': 'Nivel de zoom del mapa',
  'tooltip.title.media': 'Carga de medios',
  'tooltip.title.nfcTagId': 'ID de etiqueta NFC',
  'tooltip.title.photo': 'Subir foto',
  'tooltip.title.shiftGeofenceRestriction':
    'Agregar restricciones para comenzar un turno',
  'tooltip.title.signalStrengthIndicator':
    'Indicador de intensidad de señal recibida',
  'tooltip.title.timezone': 'Zona horaria',
  'tooltip.title.templateUserRoles': 'Roles de usuario',
  'tooltip.title.transmittingPower': 'Potencia de transmisión',
  'tooltip.title.validationError': 'Error de validacion',
  'tooltip.title.timegateSIN': 'Número de identificación del sitio (SIN)',
  'tooltip.title.templaSiteCode': 'Código del sitio',
  'tooltip.title.endShiftRestriction': 'Restricciones de fin de turno',
  'tooltip.message.endShiftRestriction':
    'Permita cualquier ubicación, restrinja a la ubicación de inicio de turno o use siempre la ubicación de inicio de turno para finalizar los turnos. Todas las opciones registrarán el GPS sin procesar del usuario y si se encontraban dentro de la geocerca de ubicación de inicio de turno.',

  'users.authStrategyTooltip.message':
    'Si esta función se encuentra disponible para su empresa, elija su proveedor de servicios de autenticación de usuario de preferencia.',
  'users.authStrategyTooltip.title': 'Proveedor de servicios de autenticación',
  'users.dayforce.message':
    'Para los clientes de Dayforce, puede hacer referencia al número de placa de empleado relacionado',
  'users.dayforce.employeeBadgeNumber': 'Número de placa de empleado',
  'users.endShiftTooltip.message':
    'Si configura Finalizar turno para Automáticamente, el usuario finalizará un nuevo turno tan pronto como cierre sesión en la aplicación móvil de Lighthouse. Esto elimina la necesidad de finalizar turnos manualmente en el dispositivo.',
  'users.endShiftTooltip.title': 'Finalizar turno',
  'users.locationsTooltip.message': `Agregue ubicaciones para brindar acceso solo a sitios específicos. No especificar una ubicación o un grupo de ubicaciones
  proporcionar acceso predeterminado a todos.`,
  'users.locationsTooltip.title': 'Seleccionar ubicaciones',
  'users.locationGroupsTooltip.message': `Agregue grupos de ubicaciones para proporcionar acceso a todas las ubicaciones dentro del grupo. Si no especifica una ubicación o un grupo de ubicaciones, se proporcionará acceso predeterminado a todos`,
  'users.locationGroupsTooltip.title': 'Seleccionar grupos de ubicación',
  'users.roleTooltip.message':
    "Seleccione el tipo de función del usuario para aplicar las correspondientes autorizaciones de escritura y lectura. Puede configurar y gestionar funciones en la pestaña de 'Funciones y permisos'.",
  'users.roleTooltip.title': 'Función',
  'users.startShiftTooltip.message':
    "Si configura Comenzar turno para Automáticamente, el usuario comenzará un nuevo turno tan pronto como abra sesión en la aplicación móvil de Lighthouse. Esto elimina la necesidad de comenzar turnos manualmente en el dispositivo.'",
  'users.startShiftTooltip.title': 'Comenzar turno',
  'users.timegateEmployeePINTooltip.message':
    'Para los clientes de Timegate, puede hacer referencia al PIN de empleado relacionado.',
  'users.timegateEmployeePINTooltip.title': 'PIN de empleado de Timegate',
  'users.winTeamEnabledTooltip.message':
    'Para clientes de WinTeam, pueden habilitar o inhabilitar la integración a WinTeam para este usuario.',
  'users.winTeamEnabledTooltip.title': ' WinTeam habilitado',
  'users.winteamEmployeeNumberTooltip.message':
    'Para clientes de WinTeam, pueden hacer referencia al número de empleado correspondiente.',
  'users.winteamEmployeeNumberTooltip.title': 'Número de empleado de WinTeam',
  'users.locked.message':
    'Esta cuenta está bloqueada. Introduce una contraseña para restablecer la cuenta.',

  'validation.maxLength': 'Debe tener menos de {{maxLength}} caracteres',
  'validation.numberOrDecimal':
    'El objetivo debe ser un número positivo de hasta 2 decimales',
  'validation.requiredField': 'Este es un campo obligatorio',
  'validation.targetMaxError': 'El objetivo debe ser menor o igual a 100',
  'validation.targetMinError': 'El objetivo debe ser mayor o igual que 0',
  'validation.wholeNumber': 'El objetivo debe ser un número entero positivo',

  'warning.userRole': `Si no asigna su propio rol, no tendrá acceso a los envíos de formularios.`,
  'warning.templateSignals': `Hay señales seleccionadas que no coinciden con los permisos de ubicación seleccionados anteriormente. Esto podría significar que los usuarios de la aplicación no tendrán acceso a las señales que escanean para poder acceder a esta plantilla.`,

  'work.errors.endAtTimeIsRequired':
    'Hora de finalización es un campo obligatorio',
  'work.errors.endDateAfterStartDate':
    'La fecha de finalización debe ser igual o posterior a la fecha de inicio',
  'work.errors.endTimeAfterStartTime':
    'La hora de finalización debe ser posterior a la hora de inicio',
  'work.errors.repeatMinutesNumber':
    'El campo Minutos de repetición debe ser un número',
  'work.errors.repeatMinutesOutsideBounds':
    'Minutos de repetición debe ser entre 5 y 1440 minutos',
  'work.errors.repeatMinutesWholeNumber':
    'Minutos de repetición debe ser un número entero',
  'work.errors.scheduleEndIsRequired':
    'Fin del programa es un campo obligatorio',

  'work.errors.scheduleStartIsRequired':
    'Inicio del programa es un campo obligatorio',

  'work.errors.startAtTimeIsRequired':
    'Iniciar a la hora es un campo obligatorio',
  'work.errors.weekdayOptionSelected':
    'Se debe seleccionar al menos una opción de día laborable',
  'work.header.newSchedule': 'Nuevo programa',
  'work.header.scheduledWork': 'Trabajo programado',
  'work.warning.notRepeating28th':
    'Los programas establecidos para el 29 no se repetirán en los meses que terminan el 28',
  'work.warning.notRepeating29th':
    'Los programas establecidos para el 30 no se repetirán en los meses que terminan el 29 o antes',
  'work.warning.notRepeating30th':
    'Los programas establecidos para el 31 no se repetirán en los meses que terminan en 30 o antes',
  'work.warning.repeatOnShortMonth':
    'Las repeticiones al final de los meses cortos ocurrirán durante el último día (ej. 28 de febrero, 30 de abril) ',
}

export default es419
